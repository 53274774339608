import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {
    ApolloClient,
    ApolloProvider,
} from '@apollo/client';
import { cache } from './cache';

AOS.init();
// Set up our apollo-client to point at the server we created
// this can be local or a remote endpoint
const client = new ApolloClient({
    cache,
    uri: 'https://xenia-content-1.herokuapp.com/graphql',
    headers: {},
    resolvers: {},
});

ReactDOM.render(
    <ApolloProvider client={client}>
        <App />
    </ApolloProvider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
